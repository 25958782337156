.RightColumn {
  width: calc(40% - 2em);
}

.SummaryDetails {
  font-size: 15px;
}

.SectionDetails {
  font-size: small;
}

.contactCell {
  font-size: small;
  color:rgb(62, 63, 63);
}

.contactLink {
  padding: 0 0 0 0.5em;
}

.contactCell > a,
.contactCell > a:visited,
.contactCell > a:hover,
.contactCell > a:active,
.contactCell > a:focus {
  text-decoration: none;
  color:rgb(62, 63, 63);
}

@media screen and (max-width: 671px) {
  .RightColumn {
    width: 100%;
    max-width: 5000px;
    display: flex;
    flex-direction: column-reverse;
  }
}
