.SideBarLink {
  display: block;
  padding: 12px 0px;
  color: #333131;
  font-size: large;
  border-bottom: #333131 1px solid;
}

.SideBarLink>a {
  text-decoration: none;
  color: #ebe1e1;
}


.SideBarLink>a:hover {
  text-decoration: none;
  color: #ebe1e1;
}


.SideBarIcon {
  padding-right: 0.5em;
}

/* Underline From Left */
.hvr-underline-from-left {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hvr-underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0px;
  background: #c5c5c5;
  height: 3px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-underline-from-left:hover:before, .hvr-underline-from-left:focus:before, .hvr-underline-from-left:active:before {
  right: 0;
}
