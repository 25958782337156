.Menu {
  /* margin-top: 4em; */
  padding: 0 2em;
  background-color: rgb(39, 37, 37);
  /* opacity: 0.8; */
}


@media screen and (min-width: 672px) {
    .Menu {
      display: none;
    }
  }

  @media only print {
    .Menu {
      display: none;
    }
  }