.TitleBar {
  background-color: #41413F;
  overflow: hidden;
  /* position: fixed; */
  top: 0;
  width: 100%;
  z-index: 1;
  /* opacity: 0.8; */
  height: 4em;
}

.titleList {
  display: flex;
  float:right;
  align-items: center;
  justify-content: center;
  /* border: #413f3f 1px solid; */
}

.title {
  color: white;
  line-height: 64px;
  padding: 0 1em;
  font-weight: 400;
  font-size: large;
}

.title > a {
  color: white;
}


.title > a:hover,:visited {
  color: white;
  font-style: normal;
  text-decoration: none;
}


@media only print {
  .title {
    color: black;
    line-height: 64px;
    float: left;
    padding-left: 1.3em;
    font-weight: 700;
    font-size: x-large;
    margin-right: 0px;
  }
  .TitleBar {
    background-color: rgba(255, 0, 0, 0);
    display: none;
  }
}


@media screen and (max-width: 671px) {
  .titleList {
    display: none;
  }
}
