.Footer {
  display: flex;
  padding: 1em 0 0 0;
  font-size: 13px;
  justify-content: center;
}

.FooterLink {
  padding: 0 0 0 0.5em;
}

.FooterCell {
  font-size: small;
  color:rgb(62, 63, 63);
}

.FooterCell > a,
.FooterCell > a:visited,
.FooterCell > a:hover,
.FooterCell > a:active,
.FooterCell > a:focus {
  text-decoration: none;
  color:rgb(62, 63, 63);
}
