.Language {
  font-size: 13px;
}

.Language > ul > li {
  margin-left: -24px;
}

.LanguageIcon {
  font-size: 10px;
  padding-right: 4px;
  color: rgb(28, 64, 141);
}
