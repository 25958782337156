.Burger {
  cursor: pointer;
  text-decoration: none;
  padding: 8px;
  text-align: center;
  background-color:#41413f;
  padding: 16px;
  font-size: 1.4em;
  float:right;
  display: initial;
}

@media screen and (min-width: 672px) {
  .Burger {
    display: none;
  }
}

@media only print {
  .Burger {
    display: none;
  }
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after,
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: rgb(197, 201, 206); }