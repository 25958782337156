/* width of A4 paper: 695 px, 695 - 2em = 671px */
.LeftColumn {
  width: 60%;
  margin-right: 2em;
}

.SummaryDetails {
  font-size: 15px;
}

.ExperienceDetails {
  display: flex;
  align-items: center;
}

.rightArrow {
  font-size: xx-small;
  vertical-align: middle;
  display: inline-block;
  place-self: start;
  padding-top: 0.5em;
  padding-right: 0.4em;
}

@media screen and (max-width: 671px) {
  .LeftColumn {
    width: 100%;
    margin-right: 0;
  }
}
