.paragraph {
  padding-top: 1em;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: fade-in 5s ease-in-out 0s backwards;
  color: hsl(0, 0%, 16%);
}

.paragraph > p {
  font-family: "Playfair Display", serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 1.56;
  padding: 0 40px 0 40px;
}

.footer {
  border-top: solid 1px lightgray;
  text-align: center;
  font-size: 14px;
  font-style: italic;
  padding: 10px;
  color: grey;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.footerItem {
  padding-left: 0.5em;
  display: inline;
}

.Home {
  position: relative;
  min-height: 100vh;
}

.contentWrap {
  padding-bottom: 46px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
}
