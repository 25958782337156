.period {
  min-width: 100px;
  font-size: 12px;
  color:#1f1f1f;
  align-self: baseline;
  padding-left: 1em;
}

.companyName {
  text-transform: uppercase;
  font-weight: bold;
  color: #3f3f3f;
  padding-right: 0.5em;
}

.companyName > a {
  text-decoration: none;
  color: black;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.companyIcon {
  padding-right: 0.5em;
  color: rgb(28, 64, 141);
}

.jobTitle {
  text-transform: math-auto;
  font-size: 15px;
  color: #5f5f5f;
  padding-bottom: 0.1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Experience {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.2em;
}

.ExperienceInfo {
  font-size: 15px;
}

.ExperienceTitle {
  display: flex;
  flex-direction: row;
}

@media only print {
  .companyName > a {
    color: rgb(33, 154, 200);
  }
}
