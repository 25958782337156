.CV {
  margin: 100px 2em 1em 2em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
}

.Author {
  display: none;
  padding-left: 1em;
  font-size: 48px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  color:#575555;

}

.Subtitle {
  text-align: center;
  display: none;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0.5em 2em 0.5em 2em;
  color: #5f5f5f;
}

@media print {
  a, a:visited, a:link, a:hover {
    text-decoration: none !important;
    color: #313131;
  }

  .Subtitle {
    display: block;
  }
}

@media only print {
  .CV {
    margin-top: 24px;
  }
}

.address {
  border-top: solid 1px black;
  padding-bottom: 1em;
  margin: 0 2em 0 2em;
}

.floatingIcon {
  display: flex;
  width: 32px;
  height: 32px;
  background-color:dimgray;
  position: fixed;
  right: 20px;
  bottom: 40px;
  cursor: pointer;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  visibility: visible;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -webkit-transform: translate(-50%, -50%);
}

@media only print {
  .floatingIcon {
    display: none;
  }
  .Author {
    display: block;
  }
}
